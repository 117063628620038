import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { CaseInformation, CaseSummary, ContactForm } from 'src/sections/crs/case/';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { PatientSummary } from 'src/sections/crs/patient';
import useSettings from 'src/hooks/useSettings';
import {
  useCarePlan,
  useCareTeams,
  useCommunications,
  useConditions,
  useDocumentReferences,
  useObservation,
  useObservations,
  usePatient,
  usePlanDefinitions,
  useServiceRequests,
  useTasks,
} from 'src/@nicheaim/fhir-react';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { CarePlanWrapper, WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { useParams } from 'react-router';
import Input from '../../../../components/workflow/common/components/Input';
import SelectType from '../../../../components/workflow/common/components/Select';
import DispositionType from '../../../../components/workflow/common/components/Disposition';
import CaseWorkflowComp from '../../../../components/workflow/Workflow';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import agent from 'src/api/agent';
import { CaseContactAttemptResponse } from 'src/crs/case/service/CaseService';
import { caseService } from 'src/crs/case/service';
import HiddenType from '../../../../components/workflow/common/components/Hidden';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import {
  ServiceRequestWrapper,
  WrappedServiceRequest,
} from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import _, { isArray, isEmpty } from 'lodash';
import NoteAddForm from '../../common/NoteAddForm';
import GoalsByPathway from './GoalsByPathway/GoalsByPathway';
import CaseCareTeamGrid from './CaseCareTeamGrid';
import { CareTeamWrapper } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import {
  PlanDefinitionWrapper,
  WrappedPlanDefinition,
} from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import ButtonType from 'src/components/workflow/common/components/Button';
import useAuth from 'src/hooks/useAuth';
import { getParseColumn, mapDataAndDataSchemaWorkflowInstance } from 'src/utils/utilities';
import TasksByPathway from './TasksByPathway';
import {
  TABLE_HEAD_ASSESSMENTS,
  TABLE_HEAD_CONTACT,
  TABLE_HEAD_NOTES,
} from '../../common/table-head';
import {
  mapAssessmentsForms,
  mapCommunicationsToNotesDisplay,
  mapContactAttemptsCases,
} from '../../common/common-utils';
import Iconify from 'src/components/Iconify';
import { TableCustom } from '../../../../components/TableCustom';
import { DocumentList } from '../../patient/components/patientDocuments/DocumentList';
import { referralService } from 'src/crs/referral/services';
import { AssessmentsFormsResponse } from 'src/crs/referral/services/ReferralService';
import OutboundByPathway from '../../referral/components/outboundGrid/OutboundByPathway';
import StartAssessmentsButton from '../../patient/components/StartAssessmentsButton';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import RelatedReferrals from './RelatedReferrals';
import { getReferenceResourceId, translateTableHead } from '../../helpers/common';
import useHealthCareServicesByServiceRequests from 'src/hooks/useHealthCareServicesByServiceRequests';
import { REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG } from 'src/config';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import { Communication } from 'src/@nicheaim/fhir-base/mappings/Communication';
import { Annotation, Bundle, Consent, FhirResource, Organization, PractitionerRole } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import produce from 'immer';
import { commonMenuTaskHandlers } from './TasksByPathway/handlers';
import ObservationDetails from '../../patient/components/patientHealth/observations/ObservationDetails';
import ExpandableCard from '../../../../components/common/ExpandableCard';
import { ConditionsList } from '../../patient/components/patientHealth/conditions/ConditionsList';
import { ConditionWrapper } from '../../../../@nicheaim/fhir-base/wrappers/Condition';
import moment from 'moment';
import ComplementaryInfo from 'src/components/complementary-info/ComplementaryInfo';
import useLocales from 'src/hooks/useLocales';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { GoalsPermissions, ResourceWithIncludedResources, TaskPermissions } from '../../types';
import { Add } from '@mui/icons-material';
import StandardDialogProvider from 'src/sections/careflow/common/StandardDialogProvider';
import { PrintableCarePlan } from '../../patient/components/details/PrintableCarePlan';
import { CollapsibleProvider } from 'src/contexts/CollapsibleContext';
import { ObservationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import PatientGenericServiceRequest from '../../patient/components/patientGenericReferralCases/PatientGenericServiceRequest';
import ConsentInformation, {
  getSelectOptionCustomButton as getConsentOption,
} from 'src/sections/engagement/intake/components/workflow-step/ConsentInformation';
import { getRegistries } from 'src/services/api/registry';
import { ConsentWrapper, WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import { fhirClient } from 'src/App';
import ConsentGrid, { getPractitionerRoleRelatedResources } from '../../patient/components/Consent/ConsentGrid/ConsentGrid';
import { getReferenceId } from 'src/utils/fhir';
import ScheduleAppointment from 'src/sections/engagement/patient/components/workflow-step/ScheduleAppointment';
import { formattedDateTimeZone } from 'src/utils/dates';
import EditType from 'src/components/workflow/common/components/Edit';

const getIdFromReference = (reference: string): string => reference.split('/')?.[1];

export default function CaseDetails() {
  const user = useAuth();
  const { i18n } = useLocales();

  const { themeStretch } = useSettings();

  const { id: carePlanId = null } = useParams();
  const [patientId, setPatientId] = useState<string | null>(null);
  const [workflowInstance, setWorkflowInstance] = useState<any>({});
  const [allUsers, setAllUsers] = useState([]);
  const [contactAttempt, setContactAttempt] = useState<CaseContactAttemptResponse[]>([]);
  const [openNote, setOpenNote] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [assessmentsForms, setAssessmentsForms] = useState<AssessmentsFormsResponse[]>([]);
  const [initialWorkflow, setInitialWorkflow] = useState<any>(null);

  const [
    carePlan,
    { isSuccess: isSuccessCarePlan, update: updateCarePlan, refresh: refreshCarePlan },
  ] = useCarePlan(carePlanId, { map: CarePlanWrapper });
  const [patient] = usePatient(patientId, {
    map: PatientWrapper,
  });

  const [
    serviceRequest,
    { refresh: refreshServiceRequest, create: createServiceRequest, update: updateServiceRequest },
  ] = useServiceRequests({
    filter: { category: 'outbound_referral', 'based-on': carePlan?.id, subject: patient?.id },
    map: ServiceRequestWrapper,
  });

  const [communicationsQuery, setCommunicationsQuery] = useState({});

  const [communications, { refresh: refreshCommunications, create: createCommunication }] =
    useCommunications({
      filter: communicationsQuery,
      map: CommunicationWrapper,
    });

  const [
    documentReferences, { refresh: refreshDocumentReference },
  ] = useDocumentReferences({ filter: { related: carePlan?.id }, map: DocumentReferenceWrapper });
  // Get tasks
  const [tasks, { refresh: refreshTasksEntities }] = useTasks({
    filter: {
      _id: carePlan?.getRelatedTaskIds()?.join(',') ?? null,
    },
    autofetch: !!carePlan?.getRelatedTaskIds()?.join(','),
    map: TaskWrapper,
  });

  const [careTeams, { refresh: refreshCareTeams }] = useCareTeams({
    map: CareTeamWrapper,
    filter: {
      _id: carePlan?.getCareTeamIds().join(','),
    },
    autofetch: !!carePlan?.getCareTeamIds().length,
  });

  const [planDefinitionsRecords] = usePlanDefinitions({
    filter: {
      status: 'active',
    },
    map: PlanDefinitionWrapper,
  });

  const [conditions] = useConditions({
    filter: {
      patient: patient?.id,
    },
    map: ConditionWrapper,
    autofetch: !!patient?.id,
  });

  const [observations] = useObservations({
    filter: {
      patient: patient?.id,
    },
    map: ObservationWrapper,
    autofetch: !!patient?.id,
  });

  const planDefinitions = useMemo(
    () =>
      planDefinitionsRecords.reduce<WrappedPlanDefinition[]>(
        (distinctPlanDefinitions, planDefinition) => {
          if (
            distinctPlanDefinitions.find(
              ({ getGoalDescription }) =>
                getGoalDescription() === planDefinition.getGoalDescription()
            )
          )
            return distinctPlanDefinitions;

          return [...distinctPlanDefinitions, planDefinition];
        },
        []
      ),
    [planDefinitionsRecords]
  );

  const [caseRecord, setCaseRecord] = useState<{internalNumber:string}>({ internalNumber:'' });
  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [disposition, setDisposition] = useState(false);
  const [reject, setReject] = useState(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);
  const [openContactClientForm, setOpenContactClientForm] = useState(false);
  const [consentsModalOpen, setConsentsModalOpen] = useState(false);
  const [scheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
  
  const [checklistData, setChecklistData] = useState<any>({});
  const [metadataWorkflow, setMetadataWorkflow] = useState<any>({});
  const userEmail = user?.getCurrentUser?.()?.email;
  const [caseOption, setCaseOption] = useState<any>([]);
  const [consents, setConsents] = useState<ResourceWithIncludedResources<WrappedConsent>[]>([]);
  const [refreshConsents, setRefreshConsents] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const getInboundReferrals = useCallback(
    (carePlan: WrappedCarePlan | null, outboundReferrals: WrappedServiceRequest[]): string[] =>
      carePlan?.supportingInfo?.reduce?.<string[]>((inboundReferrals, { reference }) => {
        const referralId = getReferenceResourceId(reference ?? '');
        //if serviceRequestId is in outboundReferrals array then can't be considered as an Inbound
        if (outboundReferrals.find(({ id }) => id === referralId)) return inboundReferrals;
        if (!referralId) return inboundReferrals;
        inboundReferrals.push(referralId);
        return inboundReferrals;
      }, []) ?? [],
    []
  );

  const inboundReferralIds = useMemo(
    () => getInboundReferrals(carePlan, serviceRequest),
    [carePlan, getInboundReferrals, serviceRequest]
  );

  const { healthCareServices } = useHealthCareServicesByServiceRequests(inboundReferralIds);

  const getCaseOptionRegistry = useCallback( async () => {
    setIsLoading(true);
    const { data } = await getRegistries('case', 'case-option');
    const dataKeyValue = data?.[0]?.keyValue ? JSON.parse(data?.[0]?.keyValue) : null;
    setIsLoading(false);
    return dataKeyValue;
  }, []);

  useEffect(() => {
    const getCaseOptionRegistryAsync = async () => {
      const caseOption = await getCaseOptionRegistry();
      setCaseOption(caseOption);
    };
    getCaseOptionRegistryAsync();
  }, [getCaseOptionRegistry]);

  const getConsents = useCallback(async (): Promise<
  ResourceWithIncludedResources<WrappedConsent>[]
  > => {
    if (!patient?.id) return [];
    try {
      const url = `Consent?patient=${patient.id}&_include=Consent:source-reference&_include=Consent:consentor&_include=Consent:organization&_count=1000`;
      const response = await fhirClient.get<Bundle>(url);
      if (!response?.entry?.length) return [];
      let entries = response.entry.reduce<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []);
      const consents = entries.filter(
        ({ resourceType }) => resourceType === 'Consent'
      ) as Consent[];

      const practitionerRoleRelatedResources = await getPractitionerRoleRelatedResources(consents);

      entries = [...entries, ...practitionerRoleRelatedResources];

      return consents.reduce<ResourceWithIncludedResources<WrappedConsent>[]>(
        (resources, consentUnwrapped) => {
          if (!consentUnwrapped?.id) return resources;
          const consent = ConsentWrapper(consentUnwrapped as Consent);

          const documentReference = entries.find?.(
            ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
          );

          const organizations =
            consent?.organization?.reduce?.<Organization[]>((organizations, { reference }) => {
              const organization = entries.find?.(
                ({ id }) => id === getReferenceId(reference)
              ) as Organization;
              if (!organization) return organizations;
              return [...organizations, organization];
            }, []) ?? [];

          const performers =
            consent?.performer?.reduce?.<FhirResource[]>((resources, { reference }) => {
              const resource = entries.find?.(({ id }) => id === getReferenceId(reference));
              if (!resource) return resources;
              return [...resources, resource];
            }, []) ?? [];

          const prRelatedResources = performers.reduce<FhirResource[]>((resources, resource) => {
            if (resource?.resourceType !== 'PractitionerRole') return resources;
            const practitionerRole = resource as PractitionerRole;

            const organization = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.organization?.reference)
            );

            const practitioner = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
            );
            return [
              ...resources,
              ...(organization ? [organization] : []),
              ...(practitioner ? [practitioner] : []),
            ];
          }, []);

          return [
            ...resources,
            {
              resource: consent,
              includedResources: [
                ...(documentReference ? [documentReference] : []),
                ...organizations,
                ...performers,
                ...prRelatedResources,
              ],
            },
          ];
        },
        []
      );
    } catch (error) {
      return [];
    }
  }, [patient]);

  useEffect(() => {
    const getConsentsAsync = async () => {
      const consents = await getConsents();
      setConsents(consents);
      setRefreshConsents(false);
    };
    getConsentsAsync();
  }, [getConsents, refreshConsents]);

  /*
   ** Workflow Item Handlers
   */
  const assignCaseItemHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    const payloadForUpdateWorkflowinstance = {
      item: checklistItem,
      record: {
        ...caseRecord,
        ...workflowInstance,
      },
      value: more.selectedUser,
      attribute_name: 'ownedBy',
    };

    try {
      const response = await agent.Record.updateRecordByAttribute(payloadForUpdateWorkflowinstance);

      const payloadForSetItemCompleted = {
        item: checklistItem,
        record: {
          ...response,
        },
      };

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      refreshChecklistHandler();
    } catch (err) {
      console.log('error on trying to assignReferralItemHandler');
    }
  };
  const contactAttemptsHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setOpenContactClientForm(true);
  };
  const handlerConsents = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    itemCode,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setConsentsModalOpen(true);
  };
  const handlerConfirmationScheduleAppointment = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setScheduleAppointmentModalOpen(true);
  };
  const handlerDisposition = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
  };
  const handlerReject = async (open: any) => {
    setReject(open);
  };

  const countContactClient =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Case Note').length;

  /*
   ** Initialization Case Workflow
   */
  const CASE_NAME = 'CASE';

  useEffect(() => {
    fetchAssessments(carePlanId);
    fetchCaseRecord(carePlanId);
    fetchStageFromWorkflow();
    getAllUsers();
    fetchContactAttempt(carePlanId || '');
  }, [carePlanId]);

  const caseWorkflowData = useMemo(() => {
    return {
      id: 1,
      recordId: carePlanId,
      recordReference: CASE_NAME,
      stage: {
        id: workflowStage,
      },
      caseRecord,
      workflowInstance,
    };
  }, [workflowStage, workflowInstance]);

  const consentInfoItemData = useMemo(() => {
    const { consents: consentRegistry = [] } = caseOption ?? {};
    const data = getConsentOption(consents, consentRegistry);
    return data?.map((e) => ({
      title: e?.title,
      value: e?.sub,
    }));
  }, [consents, caseOption]);

  const WorkflowHandler = {
    ASSIGN_CASE_ITEM: {
      type: 'payload',
      handler: assignCaseItemHandler,
      data: allUsers,
    },
    CONTACT_ATTEMPT_CASE_ITEM: {
      type: 'payload',
      handler: contactAttemptsHandler,
      data: `[${countContactClient}/5]`,
    },
    ASSIGN_OWNER_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
      type: 'payload',
      handler: assignCaseItemHandler,
      data: allUsers,
    },
    CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
      type: 'payload',
      handler: handlerConsents,
      data: consentInfoItemData ? consentInfoItemData : true,
    },
    SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationScheduleAppointment,
      data: true,
    },
    CONTACT_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
      type: 'payload',
      handler: contactAttemptsHandler,
      data: true,
    },
    setDisposition: {
      type: 'confirm',
      handler: handlerDisposition,
      data: true,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => handlerReject(open),
      data: false,
    },
  };
  const WorkflowData = {
    disposition,
    reject,
  };
  const WorkflowComponentMapping: any = {
    Input,
    Select: SelectType,
    Hidden: HiddenType,
    Disposition: DispositionType,
    Button: ButtonType,
    Edit: EditType,
  };

  const fetchCaseRecord = async (id: any) => {
    const result: any = await caseService.caseApiClient.getOne(id);
    setCaseRecord(result);
  };

  const fetchStageFromWorkflow = async () => {
    const result = await agent.Workflow.getInstance(carePlanId, CASE_NAME);
    setWorkflowInstance(result);
    setWorkflowStage(result?.stage?.id);
    setInitialWorkflow(result?.workFlow?.code);
    const metadata = JSON.parse(result?.workFlow?.metadata);
    const components = metadata?.components?.workflow;
    setMetadataWorkflow(components);
  };

  /*
   ** End Initialization Case Workflow
   */

  useEffect(() => {
    if (carePlan && isSuccessCarePlan) {
      if (carePlan?.subject?.reference) {
        setPatientId(getIdFromReference(carePlan.subject.reference));
      }
      setCommunicationsQuery({ ...communicationsQuery, 'part-of': carePlan.id });
    }
  }, [carePlan, isSuccessCarePlan]);

  const fetchWorkflowInstance = async () => {
    const result: any = await agent.Workflow.getInstance(carePlanId, CASE_NAME);
    setWorkflowInstance(result);
  };

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    setAllUsers(result);
  };

  const fetchContactAttempt = async (fhirId: string) => {
    const result: any = await caseService.caseApiClient.getAllContactAttempt(fhirId);
    setContactAttempt(result);
  };

  const fetchAssessments = async (surveyId: any) => {
    const result: any = await referralService.referralApiClient.assessments(surveyId, 'CarePlan');
    if (result && isArray(result)) {
      setAssessmentsForms(mapAssessmentsForms(result));
    }
  };

  useEffect(() => {
    fetchWorkflowInstance();
    getAllUsers();
    fetchContactAttempt(carePlanId || '');
  }, [carePlanId]);

  const handleReAssingOwned = async (ownedSelected: string) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected,
          },
        },
      };

      await agent.Workflow.reAssignOwner(payload);
      fetchWorkflowInstance();
    } catch (err) {
      console.log('error on handleReAssingOwned');
    }
  };

  const handleCreateContactForm = async (data: any) => {
    let resultContactAttempt: any = {};

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...caseRecord,
        ...workflowInstance,
      },
    };

    try {
      resultContactAttempt = await caseService.createContactAttempt(data);
      if (!isEmpty(resultContactAttempt)) {
        const resultMark = await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        fetchContactAttempt(carePlanId || '');
      }
    } catch (err) {
      console.log('error on handleCreateContactForm markItemCompleted', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      console.log('externalRefreshWorkFlow in contact form', externalRefreshWorkFlow);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handlerConsentInformation = async () => {
    setRefreshConsents(true);

    const additionalParams = patient ? { patientId: patient?.id } : null;

    const { data, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      additionalParams,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: carePlanId,
      referenceName: CASE_NAME,
      data: data,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...caseRecord,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
      setRefreshConsents(false);
    } finally {
      setExternalRefreshWorkFlow(true);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmScheduleAppointment = async (data: any) => {
    const modifiedFields = {
      date_hour: data.date_hour && new Date(data.date_hour).toISOString(),
      location: data.location,
    };

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      modifiedFields,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: carePlanId,
      referenceName: CASE_NAME,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    let setCondition = JSON.parse(checklistData.conditions);
    //TODO: if manual conditions need that property in true to be validated we should have a util available in any workflow and fix the logic to make it work for single condition or group conditions.
    if (isArray(setCondition.data)) {
      //look for a manual condition in a group and mark it as completed if found
      const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
      if (manualIndex !== -1)
        setCondition.data[manualIndex].data = {
          confirm: true,
          message: i18n('patientEngagement.details.title', 'engagement'),
        };
    }
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...caseRecord,
        ...workflowInstance,
      },
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};
    try {
      result = await createCommunication(data);

      const setNote = result?.map((x1: Communication) => ({
        id: `${x1.resourceType}/${x1.id}`,
        text: x1?.payload?.[0]?.contentString,
      }));
      const note = carePlan?.note ?? [];
      const setNoteCarePlan: Annotation[] = [...setNote, ...note];

      if (setNoteCarePlan && carePlan) {
        carePlan.note = setNoteCarePlan;
        handleUpdateCase(carePlan);
      }

      setOpenNote(false);
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const handleUpdateCase = async (data: CarePlan) => {
    const id = data?.id;
    const isEditing = Boolean(id);

    let result: any = {};

    const { ...newObject } = carePlan;

    if (isEditing) {
      result = await updateCarePlan(
        produce(newObject!, (draft) => {
          draft.supportingInfo = data.supportingInfo;
          draft.note = data.note;
        })
      );
    }

    refreshCarePlan();

    return result;
  };

  const refreshEntities = useCallback(() => {
    refreshCarePlan();
  }, []);

  const memoizedRefreshTasks = useCallback(() => {
    refreshTasksEntities();
  }, []);

  const memoizedRefreshCareTeam = useCallback((careTeam) => {
    refreshCareTeams();
  }, []);

  const handlerComplementaryInfo = async (store: any) => {
    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...store,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const defaultStructure = useMemo(() => {
    const checklistItem = getParseColumn(workflowInstance, 'data')?.scope?.checklistItem;

    return {
      ...(checklistItem?.CONTACT_ATTEMPT_CASE_ITEM && {
        CONTACT_ATTEMPT_CASE_ITEM: {
          ...(checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.validatedOn
            ),
          }),
          modifiedFields: {
            ...(checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.modifiedFields?.contactOn && {
              contactOn: formattedDateTimeZone(
                checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.modifiedFields?.contactOn
              ),
            }),
            ...(checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.modifiedFields?.nextContactOn && {
              nextContactOn: formattedDateTimeZone(
                checklistItem?.CONTACT_ATTEMPT_CASE_ITEM?.modifiedFields?.nextContactOn
              ),
            }),
          },
        },
      }),
      ...(checklistItem?.CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM && {
        CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
          ...(checklistItem?.CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.CONSENT_INFORMATION_REMOTE_PATIENT_MONITORING_CASE_ITEM?.validatedOn
            ),
          }),
        },
      }),
      ...(checklistItem?.SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM && {
        SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM: {
          ...(checklistItem?.SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM?.validatedOn && {
            validatedOn: formattedDateTimeZone(
              checklistItem?.SCHEDULE_NAT_SESSION_REMOTE_PATIENT_MONITORING_CASE_ITEM?.validatedOn
            ),
          }),
        },
      }),
    };
  }, [workflowInstance]);

  const taskPermissions: TaskPermissions = useMemo(
    () => ({
      isAllowedToAssignProgram: checkAclValidation({
        acls: [crsAcls.CRS.CASE.TASKS.ASSIGN_PROGRAM],
      }),
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.EDIT] }),
      notes: {
        isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.NOTES.ADD] }),
        isAllowedToView: checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.NOTES.VIEW] }),
      },
    }),
    []
  );

  const goalsPermissions: GoalsPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.CASE.GOAL.ADD] }),
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.CASE.GOAL.EDIT] }),
      notes: {
        isAllowedToAdd: checkAclValidation({ acls: [crsAcls.CRS.CASE.GOAL.NOTES.ADD] }),
        isAllowedToView: checkAclValidation({ acls: [crsAcls.CRS.CASE.GOAL.NOTES.VIEW] }),
      },
      tasks: taskPermissions,
    }),
    []
  );

  const careTeamPermissions = useMemo(
    () => ({
      isAllowedToAdd: checkAclValidation({
        acls: [crsAcls.CRS.CASE.CARE_TEAMS.ADD],
      }),
      isAllowedToEdit: checkAclValidation({
        acls: [crsAcls.CRS.CASE.CARE_TEAMS.EDIT],
      }),
      members: {
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.ADD],
        }),
        isAllowedToDelete: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.DELETE],
        }),
        isAllowedToEdit: checkAclValidation({
          acls: [crsAcls.CRS.CASE.CARE_TEAMS.MEMBERS.EDIT],
        }),
      },
    }),
    []
  );

  const casePermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.CASE.EDIT] }),
      isAllowedViewWorkflow: checkAclValidation({ acls: [crsAcls.CRS.CASE.WORKFLOW.VIEW]}),
      isAllowedViewSummary: checkAclValidation({ acls: [crsAcls.CRS.CASE.SUMMARY.VIEW]}),
      isAllowedViewCaseDetails: checkAclValidation({ acls: [crsAcls.CRS.CASE.DETAILS.VIEW]}),
      isAllowedViewComplementaryInfo: checkAclValidation({ acls: [crsAcls.CRS.CASE.COMPLEMENTARY_INFO.VIEW]}),
      isAllowedViewRelatedReferrals: checkAclValidation({ acls: [crsAcls.CRS.CASE.RELATED_REFERRALS.VIEW]}),
      isAllowedViewConsent: checkAclValidation({ acls: [crsAcls.CRS.CASE.CONSENT.VIEW] }),
      isAllowedViewContactAttempt: checkAclValidation({ acls: [crsAcls.CRS.CASE.CONTACT_ATTEMPTS.VIEW] }),
      isAllowedAddContactAttempt: checkAclValidation({ acls: [crsAcls.CRS.CASE.CONTACT_ATTEMPTS.ADD] }) 
    }),
    []
  );
  
  const workflowPermissions = useMemo(
    () => ({
      isAllowedToViewConsent: casePermissions.isAllowedViewConsent && metadataWorkflow?.consentInformation,
      isAllowedToViewContactAttempt: casePermissions.isAllowedViewContactAttempt && metadataWorkflow?.consentInformation
    }),
    [metadataWorkflow]
  );

  const tableHeadContactAttempt = translateTableHead(TABLE_HEAD_CONTACT, 'crs');

  return (
    <Page title={i18n('case.details.title', 'crs')}>
      <StandardDialogProvider>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs
            title={i18n('case.details.title', 'crs')}
            heading=""
            links={[
              { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
              { name: `${i18n('breadcrumbs.title', 'crs')}` },
              { name: `${i18n('case.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.case },
              { name: caseRecord?.internalNumber },
            ]}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
              <PrintableCarePlan carePlanId={carePlan?.id} />
            </Grid>

            {casePermissions?.isAllowedViewWorkflow && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <CaseWorkflowComp
                  data={caseWorkflowData}
                  refreshRecord={fetchStageFromWorkflow}
                  initialWorkflow={initialWorkflow}
                  workflowHandler={WorkflowHandler}
                  workflowData={WorkflowData}
                  componentMapping={WorkflowComponentMapping}
                  refreshWorkFlowExternal={externalRefreshWorkFlow}
                  i18Path="case.details.workflow"
                  namespace="crs"
                />
              </Grid>
            )}

            {casePermissions?.isAllowedViewSummary && (
              <Grid item xs={12}>
                <Card>
                  <Typography sx={{ m: 4 }}>{i18n('case.details.caseSummary', 'crs')}</Typography>
                  <CaseSummary tasks={tasks} serviceRequest={serviceRequest} carePlan={carePlan} />
                </Card>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Typography sx={{ mt: 1.5 }} variant="overline">
                  collapse all
                </Typography>
                <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                  <Iconify
                    icon={
                      openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                    }
                  />
                </IconButton>
              </Box>
            </Grid>
            {casePermissions.isAllowedViewCaseDetails &&
              (<Grid item xs={12} md={6} lg={6}>
                <CaseInformation
                  title={i18n('case.details.caseInfo.title', 'crs')}
                  caseRecord={caseRecord}
                  workflowInstance={workflowInstance}
                  users={allUsers}
                  onOwnedAssign={handleReAssingOwned}
                  openCollapseExternal={openCollapse}
                  isAllowedToEdit={casePermissions.isAllowedToEdit}
                />
              </Grid>
            )}

            {casePermissions?.isAllowedViewComplementaryInfo &&
              (<Grid item xs={12} md={6} lg={6}>
              <ExpandableCard
                title={i18n('case.details.caseComplementaryInfo.title', 'crs')}
                openCollapseExternal={openCollapse}
              >
                  <ComplementaryInfo
                    dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
                    data={getParseColumn(workflowInstance, 'data')}
                    defaultStructure={defaultStructure}
                    handleComplementaryInfo={handlerComplementaryInfo}
                    isAllowedToEdit={casePermissions.isAllowedToEdit}
                  />
                </ExpandableCard>
              </Grid>
            )}

            <Grid item xs={12} md={6} lg={6}>
              {patient && (
                <PatientSummary
                  title={i18n('case.details.patientInformation.title', 'crs')}
                  patient={patient}
                  openCollapseExternal={openCollapse}
                />
              )}
            </Grid>

            {casePermissions?.isAllowedViewRelatedReferrals &&
              (<Grid item xs={12} md={6} lg={6}>
                <RelatedReferrals
                  isOpen={openCollapse}
                  referralIds={inboundReferralIds}
                  externalLoading={!carePlan}
                  patientId={patientId ?? ''}
                />
              </Grid>
            )}

            {/* <Grid item xs={12} md={6} lg={6}>
            <ReferralHistory
              patient={patient}
              carePlan={carePlan}
              openCollapseExternal={openCollapse}
            />
          </Grid> */}

            {REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG && (
              <Grid item xs={12}>
                <OutboundByPathway
                  patient={patient}
                  carePlan={carePlan}
                  serviceRequest={serviceRequest}
                  healthCareServices={healthCareServices}
                  refreshServiceRequest={refreshServiceRequest}
                  createServiceRequest={createServiceRequest}
                  updateServiceRequest={updateServiceRequest}
                  openCollapseExternal={openCollapse}
                />
              </Grid>
            )}

            {checkAclValidation({ acls: [crsAcls.CRS.CASE.SERVICE_REQUESTS.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.listServiceRequests.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <PatientGenericServiceRequest patient={patient} />
                </ExpandableCard>
              </Grid>
            )}

            {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.OBSERVATION.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.listObservations.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <ObservationDetails patient={patient} />
                </ExpandableCard>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONDITION.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.listconditions.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <ConditionsList patient={patient} />
                </ExpandableCard>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.CASE.GOAL.VIEW] }) && (
              <Grid item xs={12}>
                <GoalsByPathway
                  permissions={goalsPermissions}
                  planDefinitions={planDefinitions}
                  conditions={conditions}
                  observations={observations}
                  refreshEntities={refreshEntities}
                  carePlan={carePlan}
                  patient={patient}
                  tasks={tasks}
                  refreshTasks={memoizedRefreshTasks}
                  openCollapseExternal={openCollapse}
                  title=""
                />
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.CASE.TASKS.VIEW] }) && (
              <Grid item xs={12}>
                <CollapsibleProvider
                  title={i18n('case.details.listtasks.title', 'crs')}
                  openCollapseExternal={openCollapse}
                  isCollapsible={true}
                >
                  <TasksByPathway
                    permissions={taskPermissions}
                    tasks={tasks}
                    patient={patient as WrappedPatient}
                    carePlan={carePlan}
                    healthCareServices={healthCareServices}
                    refreshEntities={refreshEntities}
                    refreshTasks={memoizedRefreshTasks}
                    openCollapseExternal={openCollapse}
                    menuTaskHandlers={commonMenuTaskHandlers}
                  />
                </CollapsibleProvider>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.CASE.CARE_TEAMS.VIEW] }) && (
              <Grid item xs={12}>
                <Card>
                  <CardContent sx={{ padding: 0, py: 3 }}>
                    <CaseCareTeamGrid
                      permissions={careTeamPermissions}
                      carePlan={carePlan as WrappedCarePlan}
                      careTeams={careTeams}
                      patient={patient as WrappedPatient}
                      onSuccessfulCreation={refreshEntities}
                      onSuccessfulEdit={memoizedRefreshCareTeam}
                      onMemberUpdateSuccess={memoizedRefreshCareTeam}
                      openCollapseExternal={openCollapse}
                      showAddButton={true}
                      title={i18n('case.details.careteams.title', 'crs')}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
            {workflowPermissions.isAllowedToViewConsent && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('patients.details.consents.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <ConsentGrid patient={patient}/>
                </ExpandableCard>
              </Grid>
            )}
            {workflowPermissions.isAllowedToViewContactAttempt && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.contactAttempts.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <TableCustom
                    data={Array.isArray(contactAttempt) && mapContactAttemptsCases(contactAttempt)}
                    tableHead={tableHeadContactAttempt}
                    childrenButtons={
                      <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                        {casePermissions.isAllowedAddContactAttempt && (
                          <Button
                            size="small"
                            sx={{ height: '36px' }}
                            startIcon={<Add />}
                            style={{ position: 'absolute', top: '25px', right: '25px' }}
                            onClick={() => setOpenContactClientForm(true)}
                          >
                            {i18n('case.details.contactAttempts.buttoncontactAttempts', 'crs')}
                          </Button>
                        )}
                      </Grid>
                    }
                  />
                </ExpandableCard>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.ASSESSMENTS.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.assessmentsForm.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <TableCustom
                    data={Array.isArray(assessmentsForms) && assessmentsForms}
                    tableHead={translateTableHead(TABLE_HEAD_ASSESSMENTS, 'crs')}
                    childrenButtons={
                      <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                        {checkAclValidation({
                          acls: [crsAcls.CRS.PATIENT.ASSESSMENTS.START],
                        }) && (
                          <StartAssessmentsButton
                            keyRegistry="crs-patient-assessments"
                            patientId={patient?.id ?? ''}
                            additionalQueryParams={`&caseUUID=${carePlanId}`}
                          />
                        )}
                      </Grid>
                    }
                  />
                </ExpandableCard>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.CASE.NOTES.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard
                  title={i18n('case.details.notesDetails.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <TableCustom
                    data={mapCommunicationsToNotesDisplay(communications, [
                      'notes_case',
                      'notes_task',
                      'notes_goal',
                    ])}
                    tableHead={translateTableHead(TABLE_HEAD_NOTES, 'crs')}
                    handleOpen={() => setOpenNote(true)}
                    titleButton={
                      checkAclValidation({ acls: [crsAcls.CRS.CASE.NOTES.ADD] })
                        ? i18n('case.details.notesDetails.button', 'crs')
                        : undefined
                    }
                  />
                </ExpandableCard>
              </Grid>
            )}
            {checkAclValidation({ acls: [crsAcls.CRS.CASE.ATTACHMENTS.VIEW] }) && (
              <Grid item xs={12}>
                <ExpandableCard 
                  title={i18n('case.details.attachments.title', 'crs')}
                  openCollapseExternal={openCollapse}
                >
                  <Card style={{ boxShadow: 'none', position: 'static' }}>
                    <DocumentList
                      permissions={{
                        isAllowedToAdd: checkAclValidation({
                          acls: [crsAcls.CRS.CASE.ATTACHMENTS.ADD],
                        }),
                        isAllowedToEdit: checkAclValidation({
                          acls: [crsAcls.CRS.CASE.ATTACHMENTS.EDIT],
                        }),
                      }}
                      patient={patient}
                      documentReferences={documentReferences}
                      resource={carePlan}
                      showFilters={true}
                      refreshDocumentReference={refreshDocumentReference}
                      openCollapseExternal={openCollapse}
                      handleUpdateResource={handleUpdateCase}
                    />
                  </Card>
                </ExpandableCard>
              </Grid>
            )}
          </Grid>

          <NoteAddForm
            open={openNote}
            patient={patient}
            onCancel={() => setOpenNote(false)}
            resource={[carePlan]}
            handleCreate={handleCreateCommunicationFhir}
            typeNote="notes_case"
          />

          <ContactForm
            option={i18n('case.details.contactAttempts.caseNote', 'crs')}
            countRecord={countContactClient}
            referral={caseRecord}
            open={openContactClientForm}
            onCancel={() => setOpenContactClientForm(false)}
            handleCreateContactForm={handleCreateContactForm}
          />

          <ConsentInformation
            patient={patient}
            isLoading={isLoading}
            handlerSave={handlerConsentInformation}
            open={consentsModalOpen}
            consentOption={caseOption?.consents ?? null}
            consents={consents}
            onClose={() => setConsentsModalOpen(false)}
          />

          <ScheduleAppointment
            isOpen={scheduleAppointmentModalOpen}
            handlerIsOpen={setScheduleAppointmentModalOpen}
            handlerScheduleAppointment={handlerConfirmScheduleAppointment}
          />

        </Container>
      </StandardDialogProvider>
    </Page>
  );
}
